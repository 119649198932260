import React, { useState, useEffect } from 'react';
import { HiChevronLeft, HiChevronRight, HiChevronUp, HiSearch } from 'react-icons/hi';

const SearchAndCategory = ({ setSearchText, categories, selectedCategory, setSelectedCategory, isDarkMode }) => {
  const [showGoToTop, setShowGoToTop] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setSearchText(searchQuery);
    }, 300);
    return () => clearTimeout(debounceTimer);
  }, [searchQuery, setSearchText]);

  useEffect(() => {
    const handleScroll = () => {
      setShowGoToTop(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollContainer = (direction) => {
    const container = document.getElementById('categories-scroll-container');
    if (!container) return;
    const scrollAmount = 200;
    container.scrollBy({
      left: direction === 'left' ? -scrollAmount : scrollAmount,
      behavior: 'smooth',
    });
  };

  return (
    <div className={`sticky top-4 z-50 w-full transition-all duration-300 mx-auto max-w-5xl px-4 mb-6`}>
      <div className={`rounded-2xl shadow-lg backdrop-blur-md 
        ${isDarkMode 
          ? 'bg-gray-900/95 border border-gray-800/60' 
          : 'bg-white/95 border border-gray-200/60'
        }`}
      >
        <div className="p-4">
          {/* Search Bar */}
          <div className={`mb-3 relative group`}>
            <div className={`relative rounded-xl overflow-hidden
              ${isDarkMode 
                ? 'bg-gray-800/40 ring-1 ring-gray-700/50' 
                : 'bg-gray-50/40 ring-1 ring-gray-200/50'
              }`}
            >
              <div className="absolute inset-y-0 left-0 pl-3.5 flex items-center pointer-events-none">
                <HiSearch className={`w-4 h-4 ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`} />
              </div>
              <input
                type="text"
                className={`w-full pl-10 pr-4 py-3 text-sm transition-all duration-200
                  focus:outline-none focus:ring-2 focus:ring-purple-500/50 
                  ${isDarkMode 
                    ? 'bg-transparent text-white placeholder-gray-400' 
                    : 'bg-transparent text-gray-900 placeholder-gray-500'
                  }`}
                placeholder="Type or paste text..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                aria-label="Search"
              />
            </div>
          </div>

          {/* Categories Section */}
          <div className={`relative rounded-xl p-2 
            ${isDarkMode 
              ? 'bg-gray-800/40 ring-1 ring-gray-700/50' 
              : 'bg-gray-50/40 ring-1 ring-gray-200/50'
            }`}
          >
            <div className="flex items-center space-x-2">
              <button
                onClick={() => scrollContainer('left')}
                className={`flex-none p-1.5 rounded-lg transition-all duration-200 ${
                  isDarkMode 
                    ? 'hover:bg-gray-700/60 text-gray-400' 
                    : 'hover:bg-gray-200/60 text-gray-500'
                }`}
                aria-label="Scroll Left"
              >
                <HiChevronLeft size={18} />
              </button>

              <div
                id="categories-scroll-container"
                className="flex-1 overflow-x-auto scrollbar-hide"
              >
                <div className="flex gap-2 py-1">
                  <button
                    className={`flex-none px-4 py-1.5 text-xs font-medium rounded-lg transition-all duration-200 
                      ${selectedCategory === 'All Categories'
                        ? 'bg-purple-500 text-white shadow-sm' 
                        : isDarkMode
                          ? 'bg-gray-700/60 text-gray-300 hover:bg-gray-700/80'
                          : 'bg-white text-gray-700 hover:bg-gray-100 shadow-sm'
                      }`}
                    onClick={() => setSelectedCategory('All Categories')}
                  >
                    All Categories
                  </button>
                  {categories.map((category) => (
                    <button
                      key={category}
                      className={`flex-none px-4 py-1.5 text-xs font-medium rounded-lg transition-all duration-200 
                        ${selectedCategory === category
                          ? 'bg-purple-500 text-white shadow-sm' 
                          : isDarkMode
                            ? 'bg-gray-700/60 text-gray-300 hover:bg-gray-700/80'
                            : 'bg-white text-gray-700 hover:bg-gray-100 shadow-sm'
                        }`}
                      onClick={() => setSelectedCategory(category)}
                    >
                      {category}
                    </button>
                  ))}
                </div>
              </div>

              <button
                onClick={() => scrollContainer('right')}
                className={`flex-none p-1.5 rounded-lg transition-all duration-200 ${
                  isDarkMode 
                    ? 'hover:bg-gray-700/60 text-gray-400' 
                    : 'hover:bg-gray-200/60 text-gray-500'
                }`}
                aria-label="Scroll Right"
              >
                <HiChevronRight size={18} />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Go to Top Button */}
      {showGoToTop && (
        <button
          onClick={scrollToTop}
          className={`fixed bottom-6 right-6 p-2.5 rounded-xl shadow-lg backdrop-blur-sm 
            transition-all duration-200 transform hover:scale-105 
            ${isDarkMode
              ? 'bg-gray-800/90 hover:bg-gray-700/90 text-purple-400 border border-gray-700/50'
              : 'bg-white/90 hover:bg-gray-50/90 text-purple-600 border border-gray-200/50'
            }`}
          aria-label="Go to Top"
        >
          <HiChevronUp size={20} />
        </button>
      )}
    </div>
  );
};

export default SearchAndCategory;