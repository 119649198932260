import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Header from './components/Header';
import SearchAndCategory from './components/SearchAndCategory';
import FontCard from './components/FontCard';
import BlogPosts from './components/BlogPosts';
import Footer from './components/Footer';
import BlogPage from './components/BlogPage';
import PostPage from './components/PostPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsConditions from './components/TermsConditions';
import fontsDataJSON from './data/fontData.json'; // Import saved data
import Article from './components/Article'; // Import the Article component
import Button from './components/Button';
import AboutUs from './components/AboutUs';



const App = () => {
  const [selectedCategory, setSelectedCategory] = useState('All Categories');
  const [searchText, setSearchText] = useState('');
  const [visibleCount, setVisibleCount] = useState(10);
  const [fontsData] = useState(fontsDataJSON); // Use saved data
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setVisibleCount(10);
  };

  const getFontsToDisplay = () => {
    return selectedCategory === 'All Categories'
      ? Object.values(fontsData).flat().slice(0, visibleCount)
      : fontsData[selectedCategory]?.slice(0, visibleCount) || [];
  };

  const loadMoreFonts = () => {
    setVisibleCount((prevCount) => prevCount + 10);
  };

  const totalFonts = selectedCategory === 'All Categories'
    ? Object.values(fontsData).flat().length
    : fontsData[selectedCategory]?.length || 0;

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <Router>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />
        <main className="container mx-auto my-8 flex-1 px-4">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <SearchAndCategory
                    isDarkMode={isDarkMode}
                    setSearchText={setSearchText}
                    categories={Object.keys(fontsData)}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={handleCategoryChange}
                  />
                  {getFontsToDisplay().map((font, index) => (
                    <FontCard
                      key={index}
                      selectedFontName={`${font.fontName}`}
                      fontData={font}
                      searchText={searchText}
                      isDarkMode={isDarkMode}  // Make sure this prop is passed
                    />
                  ))}
                  {visibleCount < totalFonts && (
                    <Button
                      onClick={loadMoreFonts}
                      variant="secondary"
                      isDarkMode={isDarkMode}
                      className="mx-auto mt-8"
                    >
                      Load More
                    </Button>
                  )}
                  <Article /> {/* Add the Article component */}
                  <BlogPosts />
                  <div className="text-center mt-8">
                    <Button
                      to="/blog"
                      variant="viewMore"  // Change from 'outline' to 'viewMore'
                      isDarkMode={isDarkMode}
                    >
                      View More
                    </Button>
                  </div>
                </>
              }
            />
            <Route path="/blog" element={<BlogPage isDarkMode={isDarkMode} />} />
            <Route path="/blog/:id" element={<PostPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/AboutUs" element={<AboutUs />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
