import React, { useState, useEffect } from 'react';
import { HiClipboard, HiCheck } from 'react-icons/hi';
import transformText from '../transformText';

const FontCard = ({ selectedFontName, fontData, searchText, isDarkMode }) => {
  const [fontsMap, setFontsMap] = useState({});
  const [fontCharMaps, setFontCharMaps] = useState({});
  const [fontPlaceholders, setFontPlaceholders] = useState({});
  const [copied, setCopied] = useState(false);
  const [transformedText, setTransformedText] = useState('');

  useEffect(() => {
    if (fontData && typeof fontData === 'object') {
      let tempFontsMap = {};
      let tempFontCharMaps = {};
      let tempFontPlaceholders = {};

      tempFontsMap[fontData.fontName] = fontData.font;
      tempFontPlaceholders[fontData.fontName] = fontData.sampleChars;

      let charArray = fontData.fontChars.split('|');
      let charMapping = {};
      const standardChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

      for (let i = 0; i < standardChars.length; i++) {
        charMapping[standardChars[i]] = charArray[i] || '';
      }

      tempFontCharMaps[fontData.fontName] = charMapping;

      setFontsMap(tempFontsMap);
      setFontCharMaps(tempFontCharMaps);
      setFontPlaceholders(tempFontPlaceholders);
    }
  }, [fontData]);

  useEffect(() => {
    if (Object.keys(fontsMap).length > 0 && Object.keys(fontCharMaps).length > 0 && Object.keys(fontPlaceholders).length > 0) {
      setTransformedText(transformText(fontsMap, fontCharMaps, fontPlaceholders, searchText, selectedFontName));
    }
  }, [fontsMap, fontCharMaps, fontPlaceholders, searchText, selectedFontName]);

  const handleCopy = () => {
    navigator.clipboard.writeText(transformedText);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="top-4 z-50 w-full transition-all duration-300 mx-auto max-w-5xl px-4 mb-3">
      <div className={`rounded-lg backdrop-blur-md transition-all duration-200
        ${isDarkMode 
          ? 'bg-gray-900/95 border border-gray-800/60' 
          : 'bg-white/95 border border-gray-200/60'
        }`}
      >
        <div className="p-2.5">
          {/* Header Section */}
          <div className="flex items-center justify-between mb-1.5">
            <h2 className={`text-xs font-medium truncate mr-2 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              {selectedFontName}
            </h2>
            <button
              onClick={handleCopy}
              className={`flex items-center space-x-1 px-2 py-1 text-xs font-medium rounded-md 
                transition-all duration-200 ${
                copied
                  ? 'bg-purple-500 text-white'
                  : isDarkMode
                    ? 'bg-gray-800 text-gray-300 hover:bg-gray-700/80 ring-1 ring-gray-700/50'
                    : 'bg-white text-gray-700 hover:bg-gray-100 ring-1 ring-gray-200/50'
              }`}
            >
              {copied ? (
                <>
                  <HiCheck className="w-3 h-3" />
                  <span>Copied!</span>
                </>
              ) : (
                <>
                  <HiClipboard className="w-3 h-3" />
                  <span>Copy</span>
                </>
              )}
            </button>
          </div>

          {/* Transformed Text Input */}
          <div className={`rounded-md overflow-hidden ${
            isDarkMode 
              ? 'bg-gray-800/40 ring-1 ring-gray-700/50' 
              : 'bg-gray-50/40 ring-1 ring-gray-200/50'
          }`}>
            <input
              type="text"
              value={transformedText}
              readOnly
              className={`w-full px-2 py-1.5 text-xs transition-all duration-200
                focus:outline-none focus:ring-2 focus:ring-purple-500/50 
                ${isDarkMode 
                  ? 'bg-transparent text-white placeholder-gray-400' 
                  : 'bg-transparent text-gray-900 placeholder-gray-500'
                }`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FontCard;