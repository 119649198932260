import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800 py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 dark:text-white mb-4">
            Privacy{' '}
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-indigo-500 dark:from-purple-400 dark:to-indigo-400">
              Policy
            </span>
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            Your privacy is important to us. Learn how we collect, use, and protect your information.
          </p>
        </div>

        {/* Main Content */}
        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl dark:shadow-gray-900/50 p-6 md:p-8 space-y-8">
          {/* Effective Date Banner */}
          <div className="bg-indigo-50 dark:bg-indigo-900/30 rounded-lg p-6 mb-8">
            <div className="flex items-center justify-between flex-wrap gap-4">
              <p className="text-gray-700 dark:text-gray-200 leading-relaxed">
                <span className="font-semibold">Effective Date:</span> {privacyPolicy.effectiveDate}
              </p>
              <span className="px-4 py-2 bg-indigo-100 dark:bg-indigo-800 text-indigo-700 dark:text-indigo-200 rounded-full text-sm font-medium">
                Last Updated: {privacyPolicy.effectiveDate}
              </span>
            </div>
          </div>

          {/* Sections */}
          {privacyPolicy.sections.map((section, index) => (
            <div key={index} className="mb-8">
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white flex items-center gap-3 mb-4">
                <span className="flex items-center justify-center w-8 h-8 rounded-full bg-indigo-100 dark:bg-indigo-900 text-indigo-600 dark:text-indigo-300 text-lg">
                  {index + 1}
                </span>
                {section.title}
              </h2>
              <div className="pl-11">
                <ul className="space-y-3">
                  {section.content.map((item, i) => (
                    <li key={i} className="flex items-start gap-3">
                      <svg
                        className="w-5 h-5 text-indigo-500 dark:text-indigo-400 mt-1 flex-shrink-0"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <span className="text-gray-700 dark:text-gray-300 leading-relaxed">
                        {item}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}

          {/* Footer Section */}
          <div className="mt-12 pt-8 border-t border-gray-200 dark:border-gray-700">
            <div className="bg-gray-50 dark:bg-gray-900/50 rounded-lg p-6">
              <p className="text-sm text-gray-600 dark:text-gray-400 text-center">
                For any questions about our Privacy Policy, please contact us. We're here to help!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Privacy Policy Data
const privacyPolicy = {
  effectiveDate: '2025-02-07',
  sections: [
    {
      title: 'Information We Collect',
      content: [
        'User Input Data: Any text you enter into our tool.',
        'Cookies & Tracking Data: We use cookies to enhance your experience and track usage patterns.',
        'Google Analytics Data: We collect non-personal data (IP address, browser type, device info) to understand user behavior.',
        'AdSense Data: Our site displays ads that may track your activity for personalized advertising.',
      ],
    },
    {
      title: 'How We Use Your Information',
      content: [
        'Improve user experience and site performance.',
        'Analyze trends and website traffic.',
        'Display relevant advertisements through Google AdSense.',
        'Detect and prevent fraud or abuse of our services.',
      ],
    },
    {
      title: 'Third-Party Services',
      content: [
        'Google Analytics: Tracks site usage and performance.',
        'Google AdSense: Displays ads that may use cookies to serve personalized ads.',
      ],
    },
    {
      title: 'Your Choices & Data Control',
      content: [
        'Opt-Out of Cookies: You can disable cookies in your browser settings.',
        'Ad Personalization: You can manage Google Ad settings through Google Ad settings page.',
        'Do Not Track (DNT): We respect browser DNT settings where applicable.',
      ],
    },
    {
      title: 'Data Security',
      content: [
        'We take appropriate security measures to protect your data. However, no online service is 100% secure, so we encourage users to browse responsibly.',
      ],
    },
    {
      title: 'Updates to This Policy',
      content: [
        'We may update this Privacy Policy from time to time. Changes will be posted on this page with the updated date.',
      ],
    },
  ],
};

export default PrivacyPolicy;