import React from 'react';

const Header = ({ toggleDarkMode, isDarkMode }) => {
  return (
    <header className="bg-gradient-to-br from-purple-50 via-white to-purple-50 dark:from-gray-900 dark:via-gray-800 dark:to-gray-900 py-3 relative overflow-hidden border-b border-gray-200 dark:border-gray-700">
      {/* Background Pattern */}
      <div className="absolute inset-0 opacity-5 dark:opacity-10">
        <div className="absolute inset-0" style={{
          backgroundImage: `
            radial-gradient(circle at 25px 25px, purple 2px, transparent 0),
            radial-gradient(circle at 75px 75px, purple 1px, transparent 0)
          `,
          backgroundSize: '100px 100px'
        }}></div>
      </div>

      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4 relative">
        <div className="flex items-center space-x-4">
          <a href="https://fontifytext.com/">
            <img 
              src='fontify-text-logo.png' 
              alt='FONTIFY TEXT - COPY PASTE FONT GENERATOR'
              className="w-[280px] h-[60px]"
            />
          </a>
        </div>

        <div className="flex flex-col md:flex-row items-center md:space-x-6 mt-3 md:mt-0 px-2">
          <div className="text-base text-gray-800 dark:text-gray-200 text-center md:text-left px-2 md:px-0 mb-2 md:mb-0">
            Transform your text into art with our{' '}
            <span className="font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-600 via-pink-500 to-purple-600 dark:from-purple-400 dark:via-pink-300 dark:to-purple-400 inline">
              Fancy Font Generator!
            </span>
          </div>

          <button 
            onClick={toggleDarkMode}
            className={`p-1.5 rounded-md transition-all duration-300 transform hover:scale-105
              ${isDarkMode 
                ? 'bg-gradient-to-br from-gray-800 to-gray-700 text-white hover:from-gray-700 hover:to-gray-600' 
                : 'bg-gradient-to-br from-purple-50 to-white hover:from-purple-100 hover:to-purple-50'
              }
              flex items-center justify-center gap-1.5 font-medium shadow-md hover:shadow-purple-500/20
              w-[90px] md:w-[110px] mx-auto md:mx-0
              ${isDarkMode 
                ? 'border border-gray-600 hover:border-gray-500' 
                : 'border border-purple-200 hover:border-purple-300'
              }`}
            aria-label={isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
          >
            <span className="text-sm">
              {isDarkMode ? '☀️' : '🌙'}
            </span>
            <span className={`text-[10px] md:text-xs ${isDarkMode ? 'text-gray-200' : 'text-purple-600'}`}>
              {isDarkMode ? 'Light' : 'Dark'}
            </span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;