import React, { useRef, useState } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const AboutUs = () => {
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end end"]
  });

  const features = [
    { icon: "✨", title: "Cool & Fancy Fonts", description: "Choose from a variety of unique fonts" },
    { icon: "📋", title: "One-Click Copy & Paste", description: "Instantly copy your styled text" },
    { icon: "🌐", title: "Works Everywhere", description: "Use it on Instagram, Facebook, Twitter, TikTok, and more" },
    { icon: "🌙", title: "Dark Mode", description: "Enjoy a smooth and comfortable experience" },
    { icon: "💝", title: "100% Free", description: "No sign-ups, no hassle!" },
    { icon: "🔄", title: "Real-Time Preview", description: "See your styled text instantly as you type" }
  ];

  const benefits = [
    { icon: "📱", text: "Grab attention on social media posts and bios" },
    { icon: "💬", text: "Make your messages unique in chats" },
    { icon: "🎮", text: "Create eye-catching usernames for gaming and social accounts" }
  ];

  const opacity = useTransform(scrollYProgress, [0, 0.5], [0.4, 1]);
  const scale = useTransform(scrollYProgress, [0, 0.5], [0.8, 1]);

  const handleTryNowClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate('/', { replace: true });
  };

  return (
    <motion.div
      ref={containerRef}
      className="min-h-screen bg-gradient-to-br from-purple-50 to-indigo-50 dark:from-gray-900 dark:to-gray-800"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      <button
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className="fixed top-4 right-4 z-50 p-2 rounded-lg bg-white dark:bg-gray-800 shadow-lg md:hidden"
      >
        <svg
          className="w-6 h-6 text-gray-600 dark:text-gray-300"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          {isMenuOpen ? (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          ) : (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          )}
        </svg>
      </button>

      <motion.div
        initial={false}
        animate={{ x: isMenuOpen ? '0%' : '100%' }}
        className="fixed inset-y-0 right-0 w-64 bg-white dark:bg-gray-800 shadow-lg z-40 md:hidden"
      >
        <div className="p-6 space-y-4">
          <a href="/" className="block text-gray-800 dark:text-white hover:text-purple-600">Home</a>
          <a href="/blog" className="block text-gray-800 dark:text-white hover:text-purple-600">Blog</a>
        </div>
      </motion.div>

      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-grid-pattern opacity-5"></div>
        <div className="container mx-auto px-4 py-12 md:py-24 relative">
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="text-center max-w-4xl mx-auto"
          >
            <h1 className="text-4xl md:text-6xl font-bold mb-6 bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent leading-tight">
              Welcome to FontifyText
            </h1>
            <p className="text-lg md:text-2xl text-gray-600 dark:text-gray-300 leading-relaxed px-4">
              The easiest way to style your text with cool and fancy fonts!
            </p>
          </motion.div>
        </div>
      </div>

      <motion.section
        style={{ opacity, scale }}
        className="py-12 md:py-20 bg-white dark:bg-gray-800 shadow-xl"
      >
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-8 md:mb-12 text-gray-800 dark:text-white">
            Amazing Features
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-purple-50 dark:bg-gray-700 rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
              >
                <div className="text-4xl mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-white">
                  {feature.title}
                </h3>
                <p className="text-gray-600 dark:text-gray-300">
                  {feature.description}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      <section className="py-12 md:py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-8 md:mb-12 text-gray-800 dark:text-white">
            Why Use Fancy Fonts?
          </h2>
          <div className="max-w-3xl mx-auto space-y-4 md:space-y-6">
            {benefits.map((benefit, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className="flex items-center p-4 md:p-6 bg-white dark:bg-gray-700 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1"
              >
                <span className="text-3xl mr-4">{benefit.icon}</span>
                <p className="text-base md:text-lg text-gray-700 dark:text-gray-200">
                  {benefit.text}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.4 }}
        className="py-12 md:py-20 bg-gradient-to-r from-purple-600 to-indigo-600 text-white"
      >
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 md:mb-6">
            Start using FontifyText today!
          </h2>
          <p className="text-lg md:text-xl mb-6 md:mb-8 text-purple-100">
            Make your words stand out and create engaging content! 🚀
          </p>
          <motion.button
            onClick={handleTryNowClick}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-white text-purple-600 px-8 py-4 rounded-full text-lg font-semibold shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
          >
            Try It Now - It's Free!
          </motion.button>
        </div>
      </motion.section>

      <motion.button
        onClick={handleTryNowClick}
        className="fixed bottom-6 right-6 md:hidden bg-purple-600 text-white p-4 rounded-full shadow-lg z-50"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          />
        </svg>
      </motion.button>
    </motion.div>
  );
};

export default AboutUs;