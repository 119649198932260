import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import postsData from '../data/postsData';

const generateId = (text) => {
  return text?.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '') || '';
};

const estimateReadingTime = (content) => {
  const wordsPerMinute = 200;
  const words = content.trim().split(/\s+/).length;
  return Math.ceil(words / wordsPerMinute);
};

const markdownFiles = require.context('../posts', false, /\.md$/);

const PostPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [headings, setHeadings] = useState([]);
  const [activeHeading, setActiveHeading] = useState('');

  // Extract headings from content
  const extractHeadings = useCallback((content) => {
    const headingRegex = /^#{1,3}\s(.+)$/gm;
    const matches = [...content.matchAll(headingRegex)];
    return matches.map(match => ({
      title: match[1],
      id: generateId(match[1]),
      level: match[0].split('#').length - 1
    }));
  }, []);

  // Intersection Observer for headings
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveHeading(entry.target.id);
          }
        });
      },
      { rootMargin: '-80px 0px -80% 0px' }
    );

    headings.forEach(({ id }) => {
      const element = document.getElementById(id);
      if (element) observer.observe(element);
    });

    return () => observer.disconnect();
  }, [headings]);

  // Fetch and process content
  useEffect(() => {
    const fetchContent = async () => {
      setIsLoading(true);
      setError(null);

      const post = postsData.find((post) => post.filename === `${id}.md`);

      if (!post) {
        setError('Post not found');
        setIsLoading(false);
        return;
      }

      try {
        const filePath = `./${post.filename}`;
        const markdown = markdownFiles(filePath);
        const response = await fetch(markdown);
        const text = await response.text();
        setContent(text);
        setHeadings(extractHeadings(text));
      } catch (error) {
        console.error('Error loading markdown:', error);
        setError('Failed to load content');
      } finally {
        setIsLoading(false);
      }
    };

    fetchContent();
  }, [id, extractHeadings]);

  const readingTime = useMemo(() => estimateReadingTime(content), [content]);

  // Scroll to heading
  const scrollToHeading = (headingId) => {
    const element = document.getElementById(headingId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800">
        <div className="text-center p-8 rounded-lg bg-white dark:bg-gray-800 shadow-xl">
          <h2 className="text-2xl font-bold text-red-600 dark:text-red-400 mb-4">
            {error}
          </h2>
          <button
            onClick={() => navigate(-1)}
            className="px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }

  return (
    <section className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 transition-colors duration-300">
      <div className="container mx-auto py-12 px-4 lg:px-8 max-w-7xl">
        {/* Navigation */}
        <button
          onClick={() => navigate(-1)}
          className="mb-8 flex items-center text-gray-600 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 transition-all duration-300 group"
          aria-label="Go back to all articles"
        >
          <svg 
            className="w-6 h-6 mr-2 transition-transform duration-300 group-hover:-translate-x-1" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
          <span className="font-medium text-lg">All Articles</span>
        </button>

        <div className="lg:grid lg:grid-cols-[250px_1fr] gap-8">
          {/* Table of Contents */}
          <aside className="hidden lg:block sticky top-8 h-fit">
            <nav className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-xl">
              <h2 className="text-xl font-bold mb-4 text-gray-900 dark:text-white">
                Table of Contents
              </h2>
              {!isLoading && (
                <>
                  <div className="text-sm text-gray-600 dark:text-gray-400 mb-4">
                    {readingTime} min read
                  </div>
                  <ul className="space-y-3">
                    {headings.map(({ title, id, level }) => (
                      <li
                        key={id}
                        style={{ paddingLeft: `${(level - 1) * 1}rem` }}
                      >
                        <button
                          onClick={() => scrollToHeading(id)}
                          className={`text-left w-full ${
                            activeHeading === id
                              ? 'text-purple-600 dark:text-purple-400 font-medium'
                              : 'text-gray-600 dark:text-gray-400 hover:text-purple-600 dark:hover:text-purple-400'
                          } transition-colors duration-200`}
                        >
                          {title}
                        </button>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </nav>
          </aside>

          {/* Main Content */}
          <div className="bg-white dark:bg-gray-800 rounded-2xl p-8 shadow-2xl hover:shadow-3xl transition-all duration-300">
            {isLoading ? (
              <div className="animate-pulse space-y-8">
                <div className="h-12 bg-gray-200 dark:bg-gray-700 rounded-lg w-3/4"></div>
                {[1, 2, 3].map((i) => (
                  <div key={i} className="space-y-4">
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-full"></div>
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-5/6"></div>
                  </div>
                ))}
              </div>
            ) : (
              <ReactMarkdown
                children={content}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
                components={{
                  h1: ({ node, ...props }) => {
                    const id = generateId(props.children ? props.children[0] : '');
                    return (
                      <h1 
                        id={id} 
                        className="text-5xl font-bold mb-8 mt-4 dark:text-white scroll-mt-20"
                      >
                        <span className="bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
                          {props.children}
                        </span>
                      </h1>
                    );
                  },
                  h2: ({ node, ...props }) => {
                    const id = generateId(props.children ? props.children[0] : '');
                    return (
                      <h2 
                        id={id} 
                        className="text-3xl font-semibold mb-6 mt-12 dark:text-gray-100 relative scroll-mt-20
                          before:content-[''] before:absolute before:-bottom-2 before:left-0 before:w-16 
                          before:h-1 before:bg-gradient-to-r before:from-purple-500 before:to-blue-400"
                      >
                        {props.children}
                      </h2>
                    );
                  },
                  h3: ({ node, ...props }) => {
                    const id = generateId(props.children ? props.children[0] : '');
                    return (
                      <h3 
                        id={id} 
                        className="text-2xl font-medium mb-4 mt-8 dark:text-gray-200 scroll-mt-20
                          pl-4 border-l-4 border-purple-500"
                      >
                        {props.children}
                      </h3>
                    );
                  },
                  img: ({ node, ...props }) => (
                    <figure className="my-8 group">
                      <div className="relative overflow-hidden rounded-xl">
                        <img
                          {...props}
                          loading="lazy"
                          className="w-full h-auto object-cover rounded-xl shadow-xl 
                            transition duration-300 transform hover:scale-[1.02]"
                          alt={props.alt || 'Image'}
                        />
                        <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent 
                          opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                      </div>
                      {props.alt && (
                        <figcaption className="text-center mt-3 text-sm text-gray-600 
                          dark:text-gray-400 italic opacity-90">
                          {props.alt}
                        </figcaption>
                      )}
                    </figure>
                  ),
                  p: ({ node, ...props }) => (
                    <p className="mb-6 text-gray-700 dark:text-gray-300 leading-relaxed text-lg 
                      tracking-wide text-opacity-90">
                      {props.children}
                    </p>
                  ),
                  a: ({ node, ...props }) => (
                    <a
                      className="text-purple-600 hover:text-purple-800 dark:text-purple-400 
                        dark:hover:text-purple-300 transition-colors duration-200 font-medium 
                        underline underline-offset-4 decoration-2"
                      target={props.href?.startsWith('http') ? '_blank' : undefined}
                      rel={props.href?.startsWith('http') ? 'noopener noreferrer' : undefined}
                      {...props}
                    >
                      {props.children}
                    </a>
                  ),
                  code: ({ node, inline, className, children, ...props }) => {
                    const match = /language-(\w+)/.exec(className || '');
                    return !inline && match ? (
                      <SyntaxHighlighter
                        language={match[1]}
                        style={vscDarkPlus}
                        className="rounded-xl !my-8"
                        showLineNumbers
                        {...props}
                      >
                        {String(children).replace(/\n$/, '')}
                      </SyntaxHighlighter>
                    ) : (
                      <code
                        className="bg-purple-50 dark:bg-gray-700 rounded-lg px-3 py-1.5 
                          text-sm font-mono text-purple-700 dark:text-purple-300 
                          border border-purple-100 dark:border-gray-600"
                        {...props}
                      >
                        {children}
                      </code>
                    );
                  },
                  pre: ({ node, ...props }) => (
                    <pre className="!p-0 !m-0">{props.children}</pre>
                  ),
                  blockquote: ({ node, ...props }) => (
                    <blockquote 
                      className="border-l-4 border-purple-500 dark:border-purple-400 pl-6 my-8 
                        italic text-gray-600 dark:text-gray-400 bg-purple-50 dark:bg-gray-700 
                        p-5 rounded-r-xl text-lg"
                    >
                      {props.children}
                    </blockquote>
                  ),
                  ul: ({ node, ...props }) => (
                    <ul 
                      className="list-disc list-inside mb-6 space-y-3 text-gray-700 
                        dark:text-gray-300 pl-6 marker:text-purple-500 text-lg"
                    >
                      {props.children}
                    </ul>
                  ),
                  ol: ({ node, ...props }) => (
                    <ol 
                      className="list-decimal list-inside mb-6 space-y-3 text-gray-700 
                        dark:text-gray-300 pl-6 marker:font-medium marker:text-purple-500 text-lg"
                    >
                      {props.children}
                    </ol>
                  ),
                  table: ({ node, ...props }) => (
                    <div className="overflow-x-auto my-8">
                      <table 
                        className="min-w-full divide-y divide-gray-200 dark:divide-gray-700"
                        {...props}
                      />
                    </div>
                  ),
                  th: ({ node, ...props }) => (
                    <th 
                      className="px-6 py-3 bg-gray-50 dark:bg-gray-700 text-left 
                        text-xs font-medium text-gray-500 dark:text-gray-300 
                        uppercase tracking-wider"
                      {...props}
                    />
                  ),
                  // ... (previous code remains the same until the td component)

                  td: ({ node, ...props }) => (
                    <td 
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 
                        dark:text-gray-400 border-b border-gray-200 dark:border-gray-700"
                      {...props}
                    />
                  ),
                }}
              />
            )}

            {/* Article Footer */}
            {!isLoading && (
              <footer className="mt-12 pt-8 border-t border-gray-200 dark:border-gray-700">
                <div className="flex flex-col space-y-4 sm:flex-row sm:justify-between sm:items-center">
                  {/* Reading Time and Date */}
                  <div className="flex items-center space-x-4 text-sm text-gray-600 dark:text-gray-400">
                    <span className="flex items-center">
                      <svg 
                        className="w-5 h-5 mr-2" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth={2} 
                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" 
                        />
                      </svg>
                      {readingTime} min read
                    </span>
                    <span className="flex items-center">
                      <svg 
                        className="w-5 h-5 mr-2" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth={2} 
                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" 
                        />
                      </svg>
                      Last updated: {new Date().toLocaleDateString()}
                    </span>
                  </div>

                  {/* Social Share Buttons */}
                  <div className="flex items-center space-x-4">
                    <button
                      onClick={() => {
                        navigator.share?.({
                          title: document.title,
                          url: window.location.href,
                        }).catch(console.error);
                      }}
                      className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 
                        dark:text-gray-300 bg-gray-100 dark:bg-gray-700 rounded-lg 
                        hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors duration-200"
                    >
                      <svg 
                        className="w-5 h-5 mr-2" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth={2} 
                          d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" 
                        />
                      </svg>
                      Share
                    </button>
                  </div>
                </div>
              </footer>
            )}
          </div>
        </div>
      </div>

      {/* Reading Progress Bar */}
      <div 
        className="fixed top-0 left-0 w-full h-1 bg-gray-200 dark:bg-gray-700 z-50"
        style={{
          transform: `scaleX(${
            typeof window !== 'undefined'
              ? window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)
              : 0
          })`,
          transformOrigin: 'left',
          transition: 'transform 0.1s ease-out'
        }}
      />
    </section>
  );
};

export default PostPage;