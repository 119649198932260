import React from 'react';

const TermsConditions = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800 py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 dark:text-white mb-4">
            Terms and{' '}
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-indigo-500 dark:from-purple-400 dark:to-indigo-400">
              Conditions
            </span>
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            Please read these terms and conditions carefully before using our services.
          </p>
        </div>

        {/* Main Content */}
        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl dark:shadow-gray-900/50 p-6 md:p-8 space-y-8">
          {/* Introduction */}
          <div className="prose dark:prose-invert max-w-none">
            <div className="bg-indigo-50 dark:bg-indigo-900/30 rounded-lg p-6 mb-8">
              <p className="text-gray-700 dark:text-gray-200 leading-relaxed">
                Welcome to FontifyText.com! These terms and conditions outline the rules and regulations
                for the use of our website. By accessing this website, we assume you accept these terms
                and conditions.
              </p>
            </div>

            {/* Sections */}
            {sections.map((section, index) => (
              <div key={index} className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-900 dark:text-white flex items-center gap-3 mb-4">
                  <span className="flex items-center justify-center w-8 h-8 rounded-full bg-indigo-100 dark:bg-indigo-900 text-indigo-600 dark:text-indigo-300 text-lg">
                    {index + 1}
                  </span>
                  {section.title}
                </h2>
                <div className="pl-11">
                  <div className="text-gray-700 dark:text-gray-300 leading-relaxed">
                    {section.content}
                  </div>
                  {section.list && (
                    <ul className="mt-4 space-y-2">
                      {section.list.map((item, i) => (
                        <li
                          key={i}
                          className="flex items-start gap-2 text-gray-700 dark:text-gray-300"
                        >
                          <svg
                            className="w-5 h-5 text-indigo-500 dark:text-indigo-400 mt-1 flex-shrink-0"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          {item}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* Footer Section */}
          <div className="mt-12 pt-8 border-t border-gray-200 dark:border-gray-700">
            <div className="bg-gray-50 dark:bg-gray-900/50 rounded-lg p-6">
              <p className="text-sm text-gray-600 dark:text-gray-400 text-center">
                Last updated: February 7, 2025. For any questions about these terms, please contact us.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Section data
const sections = [
  {
    title: 'License',
    content: (
      <p>
        Unless otherwise stated, FontifyText owns the intellectual property rights for all material on
        this website. All intellectual property rights are reserved. You may access this content for
        your personal use, subject to restrictions set in these terms.
      </p>
    ),
    list: [
      'Republish material from FontifyText.com',
      'Sell, rent, or sub-license material from FontifyText.com',
      'Reproduce, duplicate, or copy material from FontifyText.com',
      'Redistribute content from FontifyText.com',
    ],
  },
  {
    title: 'User-Generated Content',
    content: (
      <p>
        Certain areas of this website may allow users to generate and post content. FontifyText does
        not claim ownership over such content; however, by posting content on our site, you grant us a
        non-exclusive license to use, reproduce, edit, and authorize others to use and reproduce your
        content in any format.
      </p>
    ),
  },
  {
    title: 'Acceptable Use',
    content: (
      <p>
        You agree not to use FontifyText.com in any way that may damage, impair, or overburden our
        website or interfere with anyone else's use of our services. You are also prohibited from
        engaging in unlawful activities, including but not limited to spamming, hacking, or uploading
        harmful material.
      </p>
    ),
  },
  {
    title: 'Disclaimer',
    content: (
      <p>
        All the materials on FontifyText.com are provided "as is." FontifyText makes no warranties,
        whether expressed or implied, and disclaims all other warranties, including without
        limitation, implied warranties or conditions of merchantability and fitness for a particular
        purpose.
      </p>
    ),
  },
  {
    title: 'Limitation of Liability',
    content: (
      <p>
        In no event shall FontifyText, its directors, employees, or affiliates be liable for any
        damages arising out of the use or inability to use our services, even if FontifyText has been
        notified of the possibility of such damages.
      </p>
    ),
  },
  {
    title: 'Governing Law',
    content: (
      <p>
        These terms shall be governed and construed in accordance with the laws of [Your Country], and
        you submit to the non-exclusive jurisdiction of the state and federal courts located in [Your
        State/Country] for the resolution of any disputes.
      </p>
    ),
  },
  {
    title: 'Changes to Terms',
    content: (
      <p>
        FontifyText reserves the right to modify these terms at any time. Your continued use of the
        website after any changes signifies your acceptance of the new terms.
      </p>
    ),
  },
];

export default TermsConditions;