import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <footer className="bg-gradient-to-br from-purple-50 via-white to-purple-50 dark:from-gray-900 dark:via-gray-800 dark:to-gray-900 py-20 mt-16 border-t border-gray-200 dark:border-gray-700 relative overflow-hidden">
      {/* Enhanced Background Pattern */}
      <div className="absolute inset-0 opacity-5 dark:opacity-10">
        <div className="absolute inset-0" style={{
          backgroundImage: `
            radial-gradient(circle at 25px 25px, purple 2px, transparent 0),
            radial-gradient(circle at 75px 75px, purple 1px, transparent 0)
          `,
          backgroundSize: '100px 100px'
        }}></div>
      </div>

      <div className="container mx-auto px-4 relative">
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-12 mb-16">
          {/* Brand Column */}
          <div className="text-center md:text-left col-span-full lg:col-span-1">
            <h3 className="text-4xl font-bold mb-6 transform hover:scale-105 transition-transform duration-300">
              <a href="https://fontifytext.com/" className="bg-clip-text text-transparent bg-gradient-to-r from-purple-600 via-pink-500 to-purple-600 dark:from-purple-400 dark:via-pink-300 dark:to-purple-400 hover:opacity-80 transition-opacity duration-300">
                FONTIFY TEXT
              </a>
            </h3>
            <p className="text-gray-600 dark:text-gray-300 leading-relaxed text-lg">
              Transform your text into art with our Fancy Font Generator!
            </p>
          </div>

          {/* Enhanced Quick Links with animations */}
          <div className="text-center md:text-left">
            <h4 className="text-xl font-semibold text-gray-800 dark:text-gray-200 mb-6 relative inline-block after:content-[''] after:absolute after:bottom-[-8px] after:left-0 after:w-1/2 after:h-1 after:bg-purple-500 after:rounded-full">
              Quick Links
            </h4>
            <ul className="space-y-3">
            <li>
            <Link 
              to="/AboutUs" 
              className="text-gray-600 dark:text-gray-400 hover:text-purple-600 dark:hover:text-purple-400 transition-all duration-300 hover:translate-x-2 inline-block"
            >
              About Us
            </Link>
          </li>
              <li><a href="/blog" className="text-gray-600 dark:text-gray-400 hover:text-purple-600 dark:hover:text-purple-400 transition-all duration-300 hover:translate-x-2 inline-block">Blog</a></li>
            </ul>
          </div>

          {/* Enhanced Legal Links */}
          <div className="text-center md:text-left">
            <h4 className="text-xl font-semibold text-gray-800 dark:text-gray-200 mb-6 relative inline-block after:content-[''] after:absolute after:bottom-[-8px] after:left-0 after:w-1/2 after:h-1 after:bg-purple-500 after:rounded-full">
              Legal
            </h4>
            <ul className="space-y-3">
              <li><a href="/privacy-policy" className="text-gray-600 dark:text-gray-400 hover:text-purple-600 dark:hover:text-purple-400 transition-all duration-300 hover:translate-x-2 inline-block">Privacy Policy</a></li>
              <li><a href="/terms-conditions" className="text-gray-600 dark:text-gray-400 hover:text-purple-600 dark:hover:text-purple-400 transition-all duration-300 hover:translate-x-2 inline-block">Terms & Conditions</a></li>
            </ul>
          </div>

          {/* Enhanced Contact Section */}
          <div className="text-center md:text-left">
            <h4 className="text-xl font-semibold text-gray-800 dark:text-gray-200 mb-6 relative inline-block after:content-[''] after:absolute after:bottom-[-8px] after:left-0 after:w-1/2 after:h-1 after:bg-purple-500 after:rounded-full">
              Connect
            </h4>
            <div className="flex items-center justify-center md:justify-start space-x-5">
              {['facebook', 'twitter', 'instagram', 'linkedin'].map((social) => (
                <a
                  key={social}
                  href={`https://${social}.com`}
                  className="p-3 rounded-full bg-gradient-to-br from-purple-50 to-white dark:from-gray-800 dark:to-gray-700 shadow-lg hover:shadow-purple-500/20 transition-all duration-300 transform hover:scale-110 hover:-translate-y-1"
                >
                  {social === 'facebook' && <FaFacebook className="w-5 h-5 text-purple-600 dark:text-purple-400" />}
                  {social === 'twitter' && <FaTwitter className="w-5 h-5 text-purple-600 dark:text-purple-400" />}
                  {social === 'instagram' && <FaInstagram className="w-5 h-5 text-purple-600 dark:text-purple-400" />}
                  {social === 'linkedin' && <FaLinkedin className="w-5 h-5 text-purple-600 dark:text-purple-400" />}
                </a>
              ))}
            </div>
          </div>
        </div>

        {/* Enhanced Bottom Bar */}
        <div className="pt-8 border-t border-gray-200 dark:border-gray-700 text-center">
          <p className="text-gray-500 dark:text-gray-400 text-sm">
            &copy; 2024 Fontify Text. All rights reserved. | Designed and Developed by{' '}
            <a href="http://appjingle.com/" className="text-purple-600 dark:text-purple-400 hover:text-purple-700 dark:hover:text-purple-300 transition-all duration-300 hover:underline">
              AppJingle Solutions
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;