import React from 'react';
import { useNavigate } from 'react-router-dom';

const Button = ({ children, onClick, variant = 'primary', className = '', isDarkMode, ...props }) => {
  const navigate = useNavigate();
  const baseStyles = "px-3 py-2 rounded-lg font-medium transition-all duration-300 transform hover:scale-102 active:scale-98 flex items-center justify-center gap-2";
  
  const variants = {
    primary: `${isDarkMode 
      ? 'bg-purple-600 hover:bg-purple-700 text-white shadow-lg shadow-purple-500/30' 
      : 'bg-purple-600 hover:bg-purple-700 text-white shadow-lg shadow-purple-500/20'
    }`,
    secondary: `${isDarkMode
      ? 'bg-gray-800 hover:bg-gray-700 text-purple-400 border border-purple-500/50 hover:border-purple-400'
      : 'bg-white hover:bg-gray-50 text-purple-600 border border-purple-500/50 hover:border-purple-500'
    }`,
    outline: `${isDarkMode
      ? 'bg-transparent hover:bg-purple-900/30 text-purple-400 border-2 border-purple-500/50 hover:border-purple-400'
      : 'bg-transparent hover:bg-purple-50 text-purple-600 border-2 border-purple-500/50 hover:border-purple-500'
    }`,
    viewMore: `${isDarkMode
      ? 'bg-gray-800 hover:bg-gray-700 text-purple-400 border border-purple-500/50 hover:border-purple-400 cursor-pointer'
      : 'bg-white hover:bg-gray-50 text-purple-600 border border-purple-500/50 hover:border-purple-500 cursor-pointer'
    }`
  };

  const handleViewMoreClick = (e) => {
    if (variant === 'viewMore') {
      e.preventDefault();
      navigate('/blog'); // Navigate to blog page
    } else if (onClick) {
      onClick(e);
    }
  };

  return (
    <button
      onClick={handleViewMoreClick}
      className={`${baseStyles} ${variants[variant]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
