import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import postsData from '../data/postsData';

const BlogPosts = ({ isDarkMode }) => {
  const [likes, setLikes] = useState({});
  const [userLikes, setUserLikes] = useState({});

  // Load both user likes and total likes from localStorage on mount
  useEffect(() => {
    const savedUserLikes = localStorage.getItem('userLikes');
    const savedTotalLikes = localStorage.getItem('totalLikes');
    
    if (savedUserLikes) {
      setUserLikes(JSON.parse(savedUserLikes));
    }
    if (savedTotalLikes) {
      setLikes(JSON.parse(savedTotalLikes));
    }
  }, []);

  const handleLike = (postId) => {
    if (userLikes[postId]) {
      return;
    }

    const newLikes = {
      ...likes,
      [postId]: (likes[postId] || 0) + 1
    };

    const newUserLikes = {
      ...userLikes,
      [postId]: true
    };

    // Update states
    setLikes(newLikes);
    setUserLikes(newUserLikes);

    // Save both to localStorage
    localStorage.setItem('userLikes', JSON.stringify(newUserLikes));
    localStorage.setItem('totalLikes', JSON.stringify(newLikes));
  };

  const handleShare = async (post) => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: post.title,
          text: post.description,
          url: window.location.origin + `/blog/${post.filename.replace('.md', '')}`
        });
      } catch (error) {
        console.log('Error sharing:', error);
      }
    } else {
      // Fallback copy to clipboard
      const url = window.location.origin + `/blog/${post.filename.replace('.md', '')}`;
      navigator.clipboard.writeText(url);
      alert('Link copied to clipboard!');
    }
  };

  return (
    <div className="mt-16 px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-12">
        <h2 className="text-6xl font-black text-gray-800 dark:text-gray-100 
                     bg-gradient-to-r from-purple-700 to-purple-500 bg-clip-text text-transparent
                     relative inline-block tracking-wide leading-tight
                     [text-shadow:_2px_2px_4px_rgba(0,0,0,0.1)]">
          Latest Blog Posts
        </h2>
        <p className="mt-6 text-gray-600 dark:text-gray-300 text-lg max-w-2xl mx-auto">
          Discover our latest articles and stay updated with the newest trends
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {postsData.map((post, index) => (
          <div
            key={index}
            className="group relative bg-white dark:bg-gray-800 rounded-2xl shadow-lg dark:shadow-xl 
                     dark:shadow-gray-900/30 overflow-hidden transition-all duration-300 
                     hover:-translate-y-1 hover:shadow-xl dark:hover:shadow-gray-900/40"
          >
            <div className="relative h-48 overflow-hidden">
              <img
                src={post.imageUrl}
                alt={post.title}
                className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900/50 to-transparent" />
            </div>
            
            <div className="p-6 space-y-4">
              <h3 className="text-xl font-bold text-gray-800 dark:text-gray-100 transition-colors duration-300 
                           group-hover:text-purple-600 dark:group-hover:text-purple-400">
                {post.title}
              </h3>
              <p className="text-gray-600 dark:text-gray-300 text-sm line-clamp-3 transition-colors duration-300">
                {post.description}
              </p>
              
              <div className="flex items-center space-x-4 text-sm">
                <div className="flex items-center space-x-4">
                  <button 
                    onClick={() => handleLike(post.id)}
                    className={`flex items-center space-x-2 transition-colors duration-200
                      ${userLikes[post.id]
                        ? 'text-purple-600 dark:text-purple-400 cursor-not-allowed'
                        : 'text-gray-600 dark:text-gray-400 hover:text-purple-600 dark:hover:text-purple-400'
                      }`}
                    disabled={userLikes[post.id]}
                    title={userLikes[post.id] ? "You've already liked this post" : "Like this post"}
                  >
                    <svg className={`w-5 h-5 ${userLikes[post.id] ? 'fill-current' : ''}`} 
                         fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                    </svg>
                    <span>
                      {((likes[post.id] || 0) + (post.likes || 0))}
                    </span>
                  </button>

                  <button 
                    onClick={() => handleShare(post)}
                    className="flex items-center space-x-2 text-gray-600 dark:text-gray-400 hover:text-purple-600 dark:hover:text-purple-400 transition-colors duration-200"
                    title="Share this post"
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                            d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                    </svg>
                  </button>
                </div>
              </div>

              <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
                <Link
                  to={`/blog/${post.filename.replace('.md', '')}`}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-all duration-300 
                           bg-purple-100 text-purple-700 hover:bg-purple-200 
                           dark:bg-purple-600/20 dark:text-purple-400 dark:hover:bg-purple-600/30
                           group-hover:translate-x-2"
                >
                  Read More
                  <svg
                    className="w-4 h-4 ml-2 transition-transform duration-300 group-hover:translate-x-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogPosts;